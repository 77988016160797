import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  title: string;
}

type SiteMetadataContent = {
  site: {
    siteMetadata: {
      defaultTitle: string;
      titleTemplate: string;
      defaultDescription: string;
      siteUrl: string;
      defaultImage: string;
    };
  };
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;

const SEO: React.FC<SEOProps> = ({ title }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery<SiteMetadataContent>(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seoTitle = title || defaultTitle;
  const seoUrl = `${siteUrl}${pathname}`;
  const seoImage = `${siteUrl}${defaultImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'fr',
      }}
      title={seoTitle}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={defaultDescription} />
      <meta name="image" content={seoImage} />

      {/* Open Graph / Facebook */}
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:url" content={seoUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={defaultDescription} />
      <meta property="og:image" content={seoImage} />
      <meta property="og:locale" content="fr_FR" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={defaultDescription} />
      <meta name="twitter:image" content={seoImage} />
    </Helmet>
  );
};

export default SEO;
